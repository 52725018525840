<template>
  <div class="container">
    <div class="tools">
      <div class="tools-search">
        <div class="tools-top">
          <div class="tools-search__item">
            <label>盲盒名称：</label>
            <el-input
              v-model.trim="searchForm.boxNoOrName"
              size="mini"
              :maxlength="25"
              placeholder="盲盒ID/名称"
            ></el-input>
          </div>
          <div class="tools-search__item">
            <label>创建日期：</label>
            <el-date-picker
              v-model="searchForm.createTime"
              align="right"
              type="date"
              size="mini"
              value-format="yyyy-MM-dd"
              placeholder="请选择创建日期"
            >
            </el-date-picker>
          </div>
          <div class="tools-search__item">
            <label>盲盒状态：</label>
            <el-select v-model="searchForm.isDisable" size="mini" placeholder="请选择盲盒状态">
              <el-option label="已启用" :value="0"></el-option>
              <el-option label="已停用" :value="1"></el-option>
            </el-select>
          </div>
          <div class="tools-button">
            <el-button type="text" @click="collapse = !collapse">{{
              collapse ? '收起' : '展开'
            }}</el-button>
            <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
            <el-button
              size="mini"
              icon="el-icon-refresh-left
"
              @click="onReset"
              >重置</el-button
            >
          </div>
        </div>
        <el-collapse-transition>
          <div v-show="collapse" class="tools-bottom">
            <div class="tools-search__item">
              <label>抽奖企业：</label>
              <el-input
                v-model.trim="searchForm.companyName"
                size="mini"
                :maxlength="25"
                placeholder="请输入企业名称"
              ></el-input>
            </div>
            <div class="tools-search__item">
              <label>盲盒类型：</label>
              <el-select v-model="searchForm.boxType" size="mini" placeholder="请选择盲盒类型">
                <el-option label="企业" :value="0"></el-option>
                <el-option label="个人" :value="1"></el-option>
              </el-select>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <div class="tools__add">
        <el-button size="mini" type="primary" icon="el-icon-plus" @click="onAddBlindBox"
          >新增</el-button
        >
        <el-button size="mini" icon="el-icon-delete" @click="onDelAll">删除</el-button>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="dataList"
      border
      :max-height="803"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
        :selectable="(e) => e.isDisable === 1"
      ></el-table-column>
      <el-table-column label="序号" width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="boxNo" label="盲盒ID" min-width="120px"></el-table-column>
      <el-table-column prop="boxName" label="盲盒名称" min-width="180px"> </el-table-column>
      <el-table-column prop="createTime" label="创建日期" min-width="120px"> </el-table-column>
      <el-table-column prop="userName" label="创建人"> </el-table-column>
      <el-table-column label="盲盒类型">
        <template slot-scope="scope">
          {{ scope.row.boxType === 0 ? '企业' : '个人' }}
        </template>
      </el-table-column>
      <el-table-column label="抽奖企业">
        <template slot-scope="scope">
          {{ scope.row.boxType === 0 ? scope.row.companyName : '个人' }}
        </template>
      </el-table-column>
      <el-table-column prop="boxDrawDeadline" label="抽奖截止日期" min-width="120px">
      </el-table-column>
      <el-table-column prop="boxRedeemDeadline" label="兑奖截止时间" min-width="120px">
      </el-table-column>
      <el-table-column label="奖品" min-width="400px">
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.prizeDtoList" :key="index">
            {{ item.prizeName + ' ' + item.probability + '%; ' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="boxNumber" label="用户抽奖次数" min-width="120px">
        <template slot-scope="scope">
          {{ scope.row.boxNumber === -1 ? '不限次数' : scope.row.boxNumber + '次' }}
        </template>
      </el-table-column>
      <el-table-column prop="isDisable" label="盲盒状态">
        <template slot-scope="scope">
          <el-link
            style="cursor: text"
            :underline="false"
            :type="scope.row.isDisable === 0 ? 'success' : 'info'"
            >{{ scope.row.isDisable === 0 ? '已启用' : '已停用' }}</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="120px" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="onIsDisable(scope.row)">{{
            scope.row.isDisable === 0 ? '停用' : '启用'
          }}</el-button>
          <el-button
            type="text"
            size="small"
            :disabled="scope.row.isDisable !== 1"
            @click="onEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            :disabled="scope.row.isDisable !== 1"
            type="text"
            size="small"
            @click="onDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="page.current"
      :page-size="page.size"
      background
      :page-sizes="[10, 20, 30, 40]"
      layout="prev, pager, next, sizes, jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  getBlindBoxList,
  updateBlindBoxIsDisable,
  deleteBlindBoxAll,
  deleteBlindBox,
} from '@/api/blindBox.js'
import request from '@/mixins/request.js'
export default {
  mixins: [request],
  data() {
    return {
      collapse: false,
      selectionBlindBox: [],
      bizLineId: 1,
    }
  },
  mounted() {
    this.getData(getBlindBoxList)
    this.bizLineId = this.$route.query.bizLineId || 1
  },
  methods: {
    /**
     * 新增奖品点击事件
     */
    onAddBlindBox() {
      this.$router.push({
        name: 'addAndEditBlindBox',
        query: { bizLineId: this.bizLineId },
      })
    },
    /**
     * 编辑奖品点击事件
     */
    onEdit(row) {
      this.$router.push({
        name: 'addAndEditBlindBox',
        query: { id: row.boxId, bizLineId: this.bizLineId },
      })
    },
    /**
     * 选中事件
     */
    handleSelectionChange(e) {
      console.log('选中', e)
      this.selectionBlindBox = e
    },
    /**
     * 批量删除
     */
    async onDelAll() {
      if (!this.selectionBlindBox.length) {
        return this.$message.error('请选择需要删除的数据~')
      }
      try {
        console.log(this.selectionBlindBox)

        await this.confirm()
        const data = this.selectionBlindBox.map((e) => e.boxId)
        await deleteBlindBoxAll({ data })
        this.$message.success(`删除成功！`)
        this.getData()
      } catch (error) {}
    },
    /**
     * 启用、停用盲盒
     */
    async onIsDisable(row) {
      try {
        await updateBlindBoxIsDisable({ data: row.boxId })
        this.$message.success(`${row.isDisable === 1 ? '启用' : '停用'}成功！`)
        this.getData()
      } catch (error) {}
    },
    /**
     * 删除盲盒
     */
    async onDel(row) {
      try {
        await this.confirm()
        await deleteBlindBox({ data: row.boxId })
        this.$message.success(`删除成功！`)
        this.getData()
      } catch (error) {}
    },
    async confirm() {
      return await this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 24px 20px;
  box-sizing: border-box;
}
.tools {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: space-between;
  &-search {
    overflow: hidden;
    margin-right: 20px;
    ::v-deep .el-input {
      width: 160px;
    }
    &__item {
      display: flex;
      align-items: center;
      height: 40px;
      margin-right: 20px;
      label {
        width: 90px;
        padding-right: 10px;
        font-size: 14px;
        color: #606266;
        box-sizing: border-box;
        text-align: right;
      }
    }
  }
  &-top {
    display: flex;
  }
  &-bottom {
    display: flex;
  }
  &__add {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.add-award {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-upload {
    width: 368px;
    display: flex;
    &__tips {
      font-size: 13px;
      color: #9a9d9e;
      font-weight: 400;
      line-height: 24px;
      margin-left: 12px;
    }
  }
}
::v-deep .cell {
  word-break: break-all;
  word-wrap: break-word;
}
::v-deep .is-disabled {
  line-height: 0;
}
</style>
